<template>
  <div>
    <v-dialog
        v-model="dialogShow"
        :max-width="formDialogOptions.width"
        :style="{ zIndex: formDialogOptions.zIndex }"
        @keydown.esc.prevent="closeForm"
        persistent
        :fullscreen="$vuetify.breakpoint.smAndDown"
        :hide-overlay="$vuetify.breakpoint.smAndDown"
    >
      <v-card>
        <v-toolbar dark color="primary" dense>
          <v-btn icon dark @click="closeForm">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title>Riwayat Aktivitas</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
          <v-card-title class="mb-0 pb-0 font-weight-black">
            {{itemModified.description}}
          </v-card-title>
          <v-card-text class="mb-0 mt-0 pt-0 font-weight-light text-subtitle-1 grey--text">
            {{itemModified.nip}}
          </v-card-text>

          <v-card-text class="mt-0">
            <div class="my-8" v-if="itemsFtRegistrasiLog.length === 0">
              <v-row justify="center" class="font-weight-light text-subtitle-2">
                Tidak ada riwayat aktivitas
              </v-row>
            </div>
            <v-timeline
                class="mt-0 pt-0"
                v-if="itemsFtRegistrasiLog.length > 0"
                align-top
                dense>
              <v-timeline-item class="py-n1 my-n1" v-for="(item, index) in itemsFtRegistrasiLog" :key="index"
                  :color="index === itemsFtRegistrasiLog.length - 1? 'teal' : 'grey'"
                  small
              >
                <v-row class="py-0 my-0">
                  <v-col class="py-0 my-0">
                    <div class="text-caption my-0 py-0 font-weight-bold">{{item.remarks}}</div>
                    <div class="text-caption my-n2 py-n2">
                      {{dateTimeFormat(item.created)}}, oleh <span class="orange--text">{{item.modifiedBy}}</span>
                    </div>
                  </v-col>
                </v-row>
              </v-timeline-item>
            </v-timeline>
          </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ERegistrasiLog, {ERegistrasiLogs} from "@/models/e-registrasi-log";
import FtRegistrasiLogService from "@/services/apiservices/ft-registrasi-log-service";
import FtRegistrasi from "@/models/ft-registrasi";
import FtRegistrasiService from "@/services/apiservices/ft-registrasi-service";
import {format, parseISO} from "date-fns";

export default {
  name: "FtRegistrasiLogDialog",
  components: {},
  props: {
  },
  data() {
    return {
      itemsERegistrasiLog: ERegistrasiLogs,
      ERegistrasiLog: ERegistrasiLog,
      title: "APPROVAL",
      dialogShow: false,
      formDialogOptions: {
        title1: "",
        title2: "",
        message1: "",
        message2: "",
        errorMessage: "",
        color: "grey lighten-3",
        width: 600,
        zIndex: 200,
        noconfirm: false,
      },

      selectedItemIndex: -1,
      itemDefault: "",
      itemModified: new FtRegistrasi(0, "", ""),
      selectFDivision: { id: 0, kode1: "", description: "" },

      valid: false,
      rulesLenght: [
        (v) => !!v || " Tidak Boleh Kosong",
        (v) => v.length <= 255 || "Maksimal 255 Karakter",
        (v) => v.length > 1 || "Harus lebih besar dari 1 karakter",
      ],
      rulesNotEmtpy: [(v) => !!v || "Tidak boleh kosong"],

      transparent: "rgba(255, 255, 255, 0)",
      itemsFtRegistrasiLog: []
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    isItemModified() {
      const defaultItem = JSON.stringify(this.itemDefault);
      const modifiedItem = JSON.stringify(this.itemModified);
      return defaultItem !== modifiedItem;
    },
  },
  watch: {
    // itemModified:{
    //   handler: function (newValue){
    //     if (newValue){
    //       this.ftemplateRegChange()
    //     }
    //   }
    // },
  },

  methods: {
    dateTimeFormat (value) {
      return value ? format(parseISO(value), 'dd MMMM yyyy hh:mm') : ''
    },
    showDialog(selectedIndex, item) {
      this.dialogShow = !this.dialogShow;
      if (selectedIndex > -1) {
        this.selectedIndex = selectedIndex;
        this.initializeEditMode(item);
      } else {
        this.itemDefault = new FtRegistrasi(0, "", "");
        this.itemModified = new FtRegistrasi(0, "", "");
        this.selectedIndex = -1;
      }
    },
    setDialogState(value) {
      this.dialogShow = value;
    },
    closeForm() {
      if (!this.isItemModified) {
        this.dialogShow = false;
        this.$emit("eventFromFormDialog1", this.itemModified);
      } else {
        this.$refs.refCloseConfirmDialog.showDialog(
          " Sudah terdapat modifikasi data",
          "Tetap tutup dan reset perubahan?"
        );
      }
    },
    passingEventFromCloseConfirm(value) {
      if (value === "OKE") this.dialogShow = false;
    },
    initializeEditMode(item) {
      this.formDialogOptions.errorMessage = "";

      FtRegistrasiService.getFtRegistrasiById(item.id).then(
        (response) => {
          this.itemDefault = Object.assign({}, response.data);
          this.itemModified = response.data;
          FtRegistrasiLogService.getAllFtRegistrasiLogByParent(response.data.id).then(
              (response) => {
                this.itemsFtRegistrasiLog = response.data
              }
          )
        },
        (error) => {
          console.log(error);
        }
      );
    },
    lookupFDivision(fdivisionBean) {
      const str = this.itemsFDivision.filter((x) => x.id === fdivisionBean);
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },
  },
};
</script>

<style scoped>
.show-btns {
  color: blue !important;
  opacity: 1;
}
</style>