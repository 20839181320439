<template>
  <div>
    <v-dialog
      v-model="dialogShow"
      :max-width="formDialogOptions.width"
      :style="{ zIndex: formDialogOptions.zIndex }"
      @keydown.esc.prevent="closeForm"
      persistent
    >
      <v-card>
        <v-form v-model="valid" ref="form">
          <v-toolbar dark color="brown" class="color-gradient-1" dense>
            <v-btn icon dark @click="closeForm">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title>{{ title }}</v-toolbar-title>

            <span class="ml-2 mr-2 font-weight-medium grey--text"> | </span>
            <span v-if="formMode === 'EDIT_FORM'">EDIT </span>
            <span class="font-weight-light ml-1 mr-1">ITEM</span>
            <span v-if="formMode === 'NEW_FORM'"> BARU</span>

            <span
              class="ml-2 mr-2 font-weight-medium grey--text"
              v-show="isItemModified"
            >
              |
            </span>
            <v-chip
              class="ma-2"
              color="warning"
              outlined
              x-small
              v-show="isItemModified"
            >
              <v-icon left> mdi-pencil </v-icon>
              modified
            </v-chip>

            <v-spacer></v-spacer>

            <v-toolbar-items>
              <v-btn
                dark
                text
                @click="save"
                :disabled="!valid || isItemModified === false"
                class="hidden-md-and-up"
              >
                Simpan
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <v-card-text class="text-center">
            <div class="subtitle-1 font-weight-bold">{{ itemModified.description }}</div>
            <div class="caption">{{ itemModified.nip }}</div>

          </v-card-text>
          <v-card-text class="d-flex text-center align-center">
<!--            0. Pending-->
<!--            1. Approve-->
<!--            2. Ditolak-->

            <v-col cols="12" sm="12" md="4">
              <v-btn large color="grey" class="rounded-lg" @click="setStatus(0)">Menunggu</v-btn>
            </v-col>
            <v-col cols="12" sm="12" md="4">

              <v-btn large color="success" class="rounded-lg" @click="setStatus(1)">
                <v-icon>mdi-check-circle</v-icon>
                Disetujui
              </v-btn>
            </v-col>
            <v-col cols="12" sm="12" md="4" class="rounded-lg">
              <v-btn large color="red lighten-2" @click="setStatus(2)">Ditolak</v-btn>
            </v-col>

          </v-card-text>

          <v-card-actions v-if="false">
            <v-chip
              class="ml-4"
              color="error"
              outlined
              close
              small
              v-show="formDialogOptions.errorMessage"
            >
              {{ formDialogOptions.errorMessage }}
            </v-chip>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              outlined
              text
              @click="closeForm"
              class="hidden-sm-and-down"
            >
              Batal
            </v-btn>
            <v-btn
              color="blue darken-1"
              outlined
              text
              @click="save"
              :disabled="!valid || isItemModified === false"
              class="hidden-sm-and-down mr-10"
            >
              Simpan
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>

      <CloseConfirmDialog
        ref="refCloseConfirmDialog"
        @eventFromCloseConfirm="passingEventFromCloseConfirm"
      ></CloseConfirmDialog>

      <v-dialog width="100px" v-model="dialogLoading">
        <v-card class="px-4 py-7">
          <v-row justify="center">
            <v-col cols="12" align="center">
              <v-progress-circular
                :size="40"
                :width="5"
                v-if="loading"
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
      <v-snackbar v-model="snackbar">
        {{ snackBarMesage }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
        </template>
      </v-snackbar>
    </v-dialog>
  </div>
</template>

<script>
import FtRegistrasiService from "../../services/apiservices/ft-registrasi-service";

import CloseConfirmDialog from "../utils/CloseConfirmDialog.vue";
import FtRegistrasi from "../../models/ft-registrasi";
import FileService from "../../services/apiservices/file-service";
import ERegistrasiLog, {ERegistrasiLogs} from "@/models/e-registrasi-log";
import FtRegistrasiLog from "@/models/ft-registrasi-log";
import FtRegistrasiLogService from "@/services/apiservices/ft-registrasi-log-service";

export default {
  components: { CloseConfirmDialog },
  props: {
    formMode: String,
    itemsFDivision: [],
    itemsFTemplateReg: [],
    itemsFArea: [],
    itemsFSubArea: [],
  },
  data() {
    return {
      itemsERegistrasiLog: ERegistrasiLogs,
      ERegistrasiLog: ERegistrasiLog,
      title: "APPROVAL",
      snackBarMesage: "",
      snackbar: false,

      dialogLoading: false,
      loading: false,

      imageOverlay: false,

      dialogShow: false,
      formDialogOptions: {
        title1: "",
        title2: "",
        message1: "",
        message2: "",
        errorMessage: "",
        color: "grey lighten-3",
        width: 600,
        zIndex: 200,
        noconfirm: false,
      },

      selectedItemIndex: -1,
      itemDefault: "",
      itemModified: new FtRegistrasi(0, "", ""),
      selectFDivision: { id: 0, kode1: "", description: "" },

      valid: false,
      rulesLenght: [
        (v) => !!v || " Tidak Boleh Kosong",
        (v) => v.length <= 255 || "Maksimal 255 Karakter",
        (v) => v.length > 1 || "Harus lebih besar dari 1 karakter",
      ],
      rulesNotEmtpy: [(v) => !!v || "Tidak boleh kosong"],

      transparent: "rgba(255, 255, 255, 0)",
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    isItemModified() {
      const defaultItem = JSON.stringify(this.itemDefault);
      const modifiedItem = JSON.stringify(this.itemModified);
      return defaultItem !== modifiedItem;
    },
  },
  watch: {
  },

  methods: {
    setStatus(status) {
      this.itemModified.approvedStatus = status
      console.log(JSON.stringify(this.itemModified))
      this.save()
      this.dialogShow = false
    },

    showDialog(selectedIndex, item) {
      console.log("====================")
      console.log(JSON.stringify(item))

      this.dialogShow = !this.dialogShow;
      if (selectedIndex > -1) {
        this.selectedIndex = selectedIndex;
        this.initializeEditMode(item);
      } else {
        this.itemDefault = new FtRegistrasi(0, "", "");
        this.itemModified = new FtRegistrasi(0, "", "");
        this.selectedIndex = -1;
      }
    },
    setDialogState(value) {
      this.dialogShow = value;
    },
    save() {
      if (this.isItemModified === false) {
        //Close aja
        this.dialogShow = false;
        this.$emit("eventFromFormDialog1", this.itemModified);
        return;
      }
      if (this.$refs.form.validate()) {
        let newItemFtRegistrasiLog = new FtRegistrasiLog()
        newItemFtRegistrasiLog.ftRegistrasiBean = this.itemModified.id
        newItemFtRegistrasiLog.actionType = 'UPDATE'
        newItemFtRegistrasiLog.oldData = JSON.stringify(this.itemDefault)?JSON.stringify(this.itemDefault):"{}"
        newItemFtRegistrasiLog.newData = JSON.stringify(this.itemModified)?JSON.stringify(this.itemModified):"{}"
        newItemFtRegistrasiLog.remarks = this.lookupItemsERegistrasiLog(ERegistrasiLog.APPROVE).description
        newItemFtRegistrasiLog.modifiedBy = this.currentUser.username

        FtRegistrasiLogService.createFtRegistrasiLog(newItemFtRegistrasiLog).then(
            () => {
            },
            error => {
              console.log(error.response)
            }
        )

        // console.log(JSON.stringify(this.itemModified))
        if (this.itemModified.id >0){
          FtRegistrasiService.updateFtRegistrasi(this.itemModified).then(
              () => {
                // console.log(response.data)
                this.$emit("eventFromFormDialogEdit", this.itemModified);
              },
              (error) => {
                // console.log(error);
                this.formDialogOptions.errorMessage = error.response.data.message;
              }
          );
        }


      }
    },

    closeForm() {
      if (!this.isItemModified) {
        this.dialogShow = false;
        this.$emit("eventFromFormDialog1", this.itemModified);
      } else {
        this.$refs.refCloseConfirmDialog.showDialog(
          " Sudah terdapat modifikasi data",
          "Tetap tutup dan reset perubahan?"
        );
      }
    },
    passingEventFromCloseConfirm(value) {
      if (value === "OKE") this.dialogShow = false;
    },
    initializeEditMode(item) {
      this.formDialogOptions.errorMessage = "";

      FtRegistrasiService.getFtRegistrasiById(item.id).then(
        (response) => {
          this.itemDefault = Object.assign({}, response.data);
          this.itemModified = response.data;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    lookupFDivision(fdivisionBean) {
      const str = this.itemsFDivision.filter((x) => x.id === fdivisionBean);
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },
    lookupItemsERegistrasiLog(id) {
      const str = this.itemsERegistrasiLog.filter(
          (x) => x.id === id
      );
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },
    lookupFInfratructureType(finfratructureTypeBean) {
      const str = this.itemsFTemplateReg.filter(
        (x) => x.id === finfratructureTypeBean
      );
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },
    lookupFSubArea(fsubAreaBean) {
      const str = this.itemsFSubArea.filter((x) => x.id === fsubAreaBean);
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },

    lookupFArea(fareaBean) {
      const str = this.itemsFArea.filter((x) => x.id === fareaBean);
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },

    lookupImageUrl(item) {
      // console.log(JSON.stringify(item))

      if (item.avatarImage === undefined || item.avatarImage === "") {
        return "./assets/images/no_image_available.jpeg";
      } else {
        return FileService.image_url_medium(item.avatarImage);
      }
    },
    lookupImageUrlLazy(item) {
      if (item.avatarImage === undefined || item.avatarImage === "") {
        return "./assets/images/no_image_available.jpeg";
      } else {
        return FileService.image_url_verylow(item.avatarImage);
      }
    },
  },
};
</script>

<style scoped>
.show-btns {
  color: blue !important;
  opacity: 1;
}
</style>